
/*===================== 
	Premery bg color 
=====================*/

::selection {
 background: #1BBCE8;
}
::-moz-selection {
 background: #1BBCE8;
}
::-webkit-selection {
  background: #1BBCE8;
}

/* Premery bg Hex color*/
.top-bar,
.pricing-tab-content-block .wt-tabs.vertical .nav-tabs li a.active,
.circle-effect-1::after,
.special-offer-outer::after,
.testimonial-5 .testimonial-pic::after,

.noTrespassingBarLineG,
.cssload-cupcakeCircle,
.site-bg-primary,
.site-button,
ol.list-num-count > li::before,

.wt-separator-two[class*="style-"]::after, 
.wt-separator-two[class*="style-"]::before,

.masonry-filter.outline-style.customize > li.active a, 
.masonry-filter.outline-style.customize > li:hover a,
.masonry-filter.button-style > li.active a, 
.masonry-filter.button-style > li a:hover, 
.masonry-filter.button-style > li a:active, 
.masonry-filter.button-style > li a:focus,

.date-style-3 .post-date i,
.date-style-3.date-skew .post-date i,
.date-style-3.date-skew .post-date i::after,

.testimonial-1 .quote-left::before, 
.testimonial-grid-1 .quote-left::before,
.testimonial-4 .testimonial-detail, 
.testimonial-grid-4 .testimonial-detail,
.testimonial-4 .testimonial-detail::after, 
.testimonial-grid-4 .testimonial-detail::before,
.wt-team-four .wt-team-skew-block .social-icons-outer::after,
.acc-site-bg-primary a,

.pagination-1 > li > a:hover, 
.pagination-1 > li > span:hover, 
.pagination-1 > li > a:focus, 
.pagination-1 > li > span:focus,
.pagination-1 > .active > a, 
.pagination-1 > .active > span, 
.pagination-1 > .active > a:hover, 
.pagination-1 > .active > span:hover, 
.pagination-1 > .active > a:focus, 
.pagination-1 > .active > span:focus,
.custom-pagination.pagination-1 > li > a:focus, 
.custom-pagination.pagination-1 > li > a:hover, 
.custom-pagination.pagination-1 > li > span:focus, 
.custom-pagination.pagination-1 > li > span:hover,

.wt-nav-pills li:hover a,

.custom-pagination.pagination-1 > .active > a, 
.custom-pagination.pagination-1 > .active > span, 
.custom-pagination.pagination-1 > .active > a:hover, 
.custom-pagination.pagination-1 > .active > span:hover, 
.custom-pagination.pagination-1 > .active > a:focus, 
.custom-pagination.pagination-1 > .active > span:focus,

.call-to-action-skew .call-to-action-left::after,
.widget-title::after,

.owl-carousel .owl-dots .owl-dot.active span, 
.owl-carousel .owl-dots .owl-dot:hover span,

.social-icons.social-square.social-primary a, 
.social-icons.social-radius.social-primary a,
.social-icons.social-square.social-darkest a:hover, 
.social-icons.social-radius.social-darkest a:hover,

.wt-tabs.border-top .nav-tabs > li.active > a::after,

.faq-block .faq-que::after, 
.faq-block .faq-ans::after,

.animate_line:hover::after, .animate_line:hover::before,
.about-com-pic::after,

.date-style-2 .post-date,
.custom-page.pager li > a:focus, 
.custom-page.pager li > a:hover,

.pricingtable-price,
.pricing-table-style-2 .pricingtable-title,

.widget_calendar table tbody td#today,
.loader span,
.our-pricing-carousel .item.active-arrow,
.pricing-tab-content-block .wt-tabs.bg-tabs .nav-tabs > li:hover > a,
.widget_categories li span.badge,
.widget_archive ul li span.badge,
.widget_calendar thead,
.widget_getintuch i,
.testimonial-5 .testimonial-pic-block.radius-bx .testimonial-pic:after,
.testimonial-grid-5 .testimonial-pic-block.radius-bx .testimonial-pic:after,
.overlay-icon a:hover,
.masonry-filter.button-style.has-bg > li.active a,
.masonry-filter.button-style.has-bg > li a:hover,
.slider-dots .tp-bullet.selected,
.slider-dots .zeus .tp-bullet::after,
.opening-block:after,
.opening-block:before,
.testimonial-2 .testimonial-detail, .testimonial-grid-2 .testimonial-detail,
.searchform input[type="submit"],.wt-tabs.border-top .nav-tabs > li > a.active::after {
	background-color:#1BBCE8;
}

/* Premery hex buttons bg color*/
.site-button {
	background-color:#1BBCE8;
}
.site-button:hover,
.site-button:focus,
.site-button:active {
	background-color:#16A7D3;
}

/* other hex buttons bg color*/
.searchform input[type="submit"] {
	background-color:#1BBCE8;
}
.searchform input[type="submit"]:hover,
.searchform input[type="submit"]:focus,
.searchform input[type="submit"]:active {
	background-color:#1BBCE8;
}


/* Premery Rgba color*/
.wt-info-has.site-bg-primary {
	background-color:rgba(27, 188, 232,0.9);
}


/*===================== 
	Premery text color 
=====================*/
/* Premery text Hex color*/
a,
h1 a:hover, 
h2 a:hover, 
h3 a:hover, 
h4 a:hover, 
h5 a:hover, 
h6 a:hover,
.site-text-primary,
ul.primary li::before,
button.scroltop,
.wt-breadcrumb li a,

.header-nav .nav > li:hover > a,
.header-nav .nav > li .sub-menu li a:hover,
.header-nav .nav > li .sub-menu li:hover > a,
.header-nav .nav > li .mega-menu > li ul a:hover,

.header-nav .nav > li.active > a, 
.header-nav .nav > li.current-menu-item > a,
.header-nav .nav li.has-child.nav-active > a,

.header-style-3 .is-fixed .header-nav .nav > li:hover > a,
.header-style-3 .is-fixed .header-nav .nav > li.active > a, 
.header-style-3 .is-fixed .header-nav .nav > li.current-menu-item > a,

.header-style-5 .is-fixed .header-nav .nav > li:hover > a,
.header-style-5 .is-fixed .header-nav .nav > li.active > a, 
.header-style-5 .is-fixed .header-nav .nav > li.current-menu-item > a,

.nav-dark.header-nav .nav > li .mega-menu > li ul a:hover,


.date-style-3 .post-date strong,
.status-value span,
.rating-bx,

.testimonial-1 .testimonial-position, 
.testimonial-grid-1 .testimonial-position,

.testimonial-2 .testimonial-text .fa-quote-left, 
.testimonial-grid-2 .testimonial-text .fa-quote-left,



.testimonial-3 .testimonial-position,
.testimonial-grid-3 .testimonial-position,

.testimonial-3 .quote-left::before, 
.testimonial-grid-3 .quote-left::before,

.testimonial-4 .testimonial-detail .fa-quote-left, 
.testimonial-grid-4 .testimonial-detail .fa-quote-left,

ol.comment-list li.comment .reply a,

.masonry-filter > li.active a, 
.masonry-filter > li a:hover, 
.masonry-filter > li a:active, 
.masonry-filter > li a:focus,
.masonry-filter.link-style.white > li.active a, 
.masonry-filter.link-style.white > li a:hover, 
.masonry-filter.link-style.white > li a:active, 
.masonry-filter.link-style.white > li a:focus,
.masonry-filter.outline-style > li.active a, 
.masonry-filter.outline-style > li a:hover, 
.masonry-filter.outline-style > li a:active, 
.masonry-filter.outline-style > li a:focus,

.widget_categories ul li a:hover, 
.widget_archive ul li a:hover, 
.widget_meta ul li a:hover, 
.widget_pages ul li a:hover, 
.widget_recent_comments ul li a:hover, 
.widget_nav_menu li a:hover, 
.widget_recent_entries ul li a:hover, 
.widget_services ul li a:hover,

.footer-dark .footer-top a:active, 
.footer-dark .footer-top a:focus, 
.footer-dark .footer-top a:hover,
.social-icons.social-light.has-bg li a:hover,

.masonry-filter.link-style.has-bg > li.active a,
.masonry-filter.outline-style.has-bg > li.active a,
.masonry-filter.link-style.has-bg > li:hover a,
.masonry-filter.outline-style.has-bg > li a:hover,
.social-icons.social-dark.has-bg li a:hover, .pro-banner-disc h3 {
	color:#1BBCE8;
}
@media only screen and (max-width:991px) {
.site-bg-primary .header-nav .nav > li:hover > a,
.site-bg-primary .header-nav .nav > li.active > a, 
.site-bg-primary .header-nav .nav > li.current-menu-item > a {
	color:#1BBCE8;
}
}
/*===================== 
	Premery border color 
=====================*/

/* Premery Hex border color*/
.masonry-filter.outline-style.customize > li.active a,
.latest-blog-1 .wt-post-media,
button.scroltop,
.bdr-primary,
input[type=radio]:checked + label:before,
input[type=checkbox]:checked + label:before,
input[type=checkbox] + label:hover:before, 
input[type=radio] + label:hover:before,

.testimonial-4 .testimonial-pic::after,
.testimonial-4 .testimonial-pic, 
.testimonial-grid-4 .testimonial-pic,

.masonry-filter.outline-style.customize > li.active a, 
.masonry-filter.outline-style.customize > li:hover a,
.masonry-filter.outline-style > li.active a, 
.masonry-filter.outline-style > li a:hover, 
.masonry-filter.outline-style > li a:active, 
.masonry-filter.outline-style > li a:focus,

.social-icons.social-square.social-dark.has-bg a:hover, 
.social-icons.social-radius.social-dark.has-bg a:hover,
.about-com-pic:after,
.pricing-table-style-4 .pricingtable-inner,
.masonry-filter.outline-style.has-bg > li.active a,
.masonry-filter.outline-style.has-bg > li a:hover,
ol.list-num-count.list-outline > li::before,
.date-style-3 .wt-post-media  {
	border-color:#1BBCE8;
}

.our-pricing-carousel .item.active-arrow:after{
	border-top: 18px solid #1BBCE8;
}
/*===================== 
	Secondery bg hex color
 =====================*/
 
/* Secondery hex buttons bg color*/
.site-button-secondry {
	background-color:#08333F;
}
.site-button-secondry:hover,
.site-button-secondry:focus,
.site-button-secondry:active {
	background-color:#614F57;
}
/* Secondery hex text color*/
.text-secondry {
	color:#08333F;
}
/* Secondery hex background color*/
.bg-secondry {
	background-color:#08333F;
}

/*===================== 
	Footer strip
 =====================*/
.constrot-strip {
    background-image:url(../../images/footer-strip-sky-blue.png);
}

/*===================== 
	Gradient
 =====================*/
.primary-gradi{
background: -moz-linear-gradient(top,  rgba(125,185,232,0) 0%, rgba(27,188,232,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(125,185,232,0) 0%,rgba(27,188,232,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(125,185,232,0) 0%,rgba(27,188,232,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#007db9e8', endColorstr='#1bbce8',GradientType=0 ); /* IE6-9 */

}